@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    height: 100%;
}

.detailBackground {
    background-position: center center;
    background-size: cover;
    background-image: url(https://picsum.photos/id/43/320/620);
}

.gradientBackgroundHeader {
    background: linear-gradient(to top, transparent 0%, black 100%);
}

.gradientBackground {
    background: linear-gradient(to bottom, transparent 0%, black 100%);
}

.gradientBackgroundFooter {
    background: linear-gradient(to bottom, transparent 0%, black 100%);
}
